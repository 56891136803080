import React from 'react';
import PropTypes from 'prop-types';
import { useUserIPGrabber } from '@cleverrealestate/clever-helpers';
import SEO from '../components/meta/SEO';
import Layout from '../components/layout/Layout';
import PageContainer from '../components/layout/PageContainer';
import useGlobalTOCHandler from '../utils/useGlobalTOCHandler';
import renderContent from '../utils/renderContent';

const LegalTemplate = ({ pageContext }) => {
  useUserIPGrabber();
  useGlobalTOCHandler();

  const { seo, title, slug, renderContext, publishedAtFormatted } = pageContext;

  const breadcrumbs = [
    {
      link: '/',
      title: 'Home',
    },
    {
      link: `/${slug}/`,
      title,
    },
  ];

  return (
    <Layout>
      <SEO
        pathname={`/${slug}/`}
        title={seo.title}
        description={seo.meta_description}
        meta={[
          {
            name: 'robots',
            content: 'noindex',
          },
        ]}
      />

      <PageContainer
        title={title}
        breadcrumbs={breadcrumbs}
        publishDate={publishedAtFormatted}
        contentWidth="default"
        bottomCTA="none"
      >
        <article id="article-content" className="rich-text">
          {renderContent(renderContext.html, renderContext.context)}
        </article>
      </PageContainer>
    </Layout>
  );
};

LegalTemplate.propTypes = {
  pageContext: PropTypes.any,
};

LegalTemplate.defaultProps = {
  pageContext: {},
};

export default LegalTemplate;
