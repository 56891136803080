import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import className from 'classnames';
import Icon from '../../atoms/Icon';

const BreadCrumbs = ({ breadcrumbs }) => (
  <div className="flex flex-wrap items-center mb-8 align-middle">
    {breadcrumbs.map((crumb, i) => (
      <div className="flex" key={`${crumb.link}|${crumb.title}`}>
        {i > 0 && (
          <Icon
            type="breadcrumbArrow"
            className="w-2 ml-3 mr-2 text-gray-200"
          />
        )}

        <Link
          to={crumb.link}
          className={className(
            'font-heading no-underline uppercase text-sm font-bold',
            {
              'text-primary-500 pointer-events-none':
                breadcrumbs.length - 1 === i,
              'text-gray-400 hover:text-primary-500 transition-colors':
                breadcrumbs.length - 1 !== i,
            },
          )}
        >
          {crumb.title}
        </Link>
      </div>
    ))}
  </div>
);

BreadCrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }),
  ),
};

BreadCrumbs.defaultProps = {
  breadcrumbs: [],
};

export default BreadCrumbs;
