import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InlineCitation from '@cleverrealestate/clever-components-v2/dist/components/InlineCitation/InlineCitation';
import Breadcrumbs from '../../molecules/Breadcrumbs';
import Button from '../../atoms/Button';
import useCheckForEditorCookie from '../../../utils/useCheckForEditorCookie';

const PageContainer = ({
  authors,
  breadcrumbs,
  children,
  containerSize,
  cosmicEditPath,
  hideAuthors,
  publishDate,
  readingTime,
  title,
}) => {
  const isEditor = useCheckForEditorCookie();
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <article
      id="article-content"
      className={classNames(
        'page-container--wrapper',
        {
          narrow: containerSize === 'narrow',
          default: containerSize === 'default',
          wide: containerSize === 'wide',
        },
        'container py-6 text-lg',
      )}
    >
      {/* Breadcrumbs */}
      <Breadcrumbs breadcrumbs={breadcrumbs} />

      {/* Title & author widget */}
      <header className="mb-6">
        {title && <h1 className="mb-2 text-4xl">{title}</h1>}

        {!hideAuthors && authors.length > 0 && (
          <div className="flex items-center text-gray-500">
            <span>
              Written by{' '}
              {authors.map((author, index) => (
                <span key={author.slug} className="font-bold">
                  <a href={`/authors/${author.slug}/`}>{author.name}</a>
                  {index < authors.length - 1 ? ', ' : ''}
                </span>
              ))}
            </span>

            {publishDate && (
              <>
                <span
                  className="inline-block w-2 h-2 mx-5 align-middle bg-gray-100 rounded-full"
                  aria-hidden="true"
                />
                <span>{publishDate}</span>
              </>
            )}

            {readingTime && (
              <>
                <span
                  className="inline-block w-2 h-2 mx-5 align-middle bg-gray-100 rounded-full"
                  aria-hidden="true"
                />
                <span>{`${readingTime} minute read`}</span>
              </>
            )}
          </div>
        )}
      </header>

      {/* Edit page button */}
      {isEditor && cosmicEditPath && (
        <div className="flex flex-row justify-between my-4">
          <Button
            label="Edit This Post"
            onClick={() => window.open(cosmicEditPath, '_blank')}
          />
        </div>
      )}

      {/* Content */}
      {children}
      {mounted && <InlineCitation />}
    </article>
  );
};

PageContainer.propTypes = {
  authors: PropTypes.array,
  breadcrumbs: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  containerSize: PropTypes.oneOf(['narrow', 'default', 'wide']),
  cosmicEditPath: PropTypes.string,
  hideAuthors: PropTypes.bool,
  publishDate: PropTypes.string,
  readingTime: PropTypes.number,
  title: PropTypes.string.isRequired,
};

PageContainer.defaultProps = {
  authors: [],
  breadcrumbs: [],
  children: [],
  containerSize: 'default',
  cosmicEditPath: null,
  hideAuthors: false,
  publishDate: null,
  readingTime: null,
};

export default PageContainer;
